// import axios from "axios"
import Api from "../../config/config.js";

export default {
  async get() {
    try {
      let getInscription = await Api.instance.get(`/inscription/get-config`);
      // console.log("get inscription",getInscription)
      return getInscription.data;
    } catch (error) {
      console.log(error);
    }
  },
  // async get(){
  //     try {
  //         let getInscription = await axios.get(`${config.api_url}/inscription/get-config`)
  //         console.log("get inscription",getInscription)
  //         return getInscription.data
  //     } catch (error) {
  //         console.log(error)
  //     }
  // },
  async getByRef(options) {
    try {
      let getInscription = await Api.instance.get(
        `/inscription/get-config-by-ref`,
        { params: options }
      );
      // console.log("get inscription",getInscription)
      return getInscription.data;
    } catch (error) {
      console.log(error);
    }
  },
  async post(options) {
    try {
      let postInscription = await Api.instance.post(
        `/inscription/generate-config`,
        options
      );
      // console.log("postInscription",postInscription)
      return postInscription.data.create;
    } catch (error) {
      console.log(error);
    }
  },
  async put(options) {
    try {
      let putInscription = await Api.instance.put(
        `/inscription/update-config`,
        options
      );
      // console.log("putInscription",putInscription)
      return putInscription.data.update;
    } catch (error) {
      console.log(error);
    }
  },
  async delete(options) {
    try {
      let deleteInscription = await Api.instance.post(
        `/inscription/delete-config`,
        options
      );
      // console.log("deleteInscription",deleteInscription)
      return deleteInscription.data.delete;
    } catch (error) {
      console.log(error);
    }
  },
};
